<template>
  <div>
    <template>
      <v-badge
        dot color="success" bordered v-model="notificationObj.newNotificationFound"
        offset-x="-41" offset-y="-14"
      >
        <v-btn fixed top left x-small fab @click="getAllUnReadNotifications" v-if="!showMoreModules">
          <v-icon size="18"> mdi-bell-outline </v-icon>
        </v-btn>
      </v-badge>
      <v-navigation-drawer
        v-model="notificationObj.showlist"
        absolute temporary width="88%"
      >
        <v-card height="100%" flat tile>
          <v-card-title
            class="pa-2"
            :style="($vuetify.theme.dark) ? '' : `
              background: ${systemDetails.themecolor};
              color: ${systemDetails.textcolor};
            `"
          >
            <v-container fluid class="pa-0">
              <v-row dense>
                <v-col cols="7">
                  <v-btn icon small dark class="ma-1 mt-0 mb-lg-0 mb-xl-1" @click="updateSelections">
                    <v-icon :color="(notificationObj.selection.length) ? 'primary lighten-3' : ''" size="20">
                      {{ (notificationObj.selection.length) ? ((notificationObj.selection.length === notificationObj.list.length) ? 'mdi-checkbox-marked' : 'mdi-minus-box') : 'mdi-bell-outline' }}
                    </v-icon>
                  </v-btn>
                  <span class="body-1 font-weight-medium mx-0"> {{ $t('notifications') }} </span>
                  <span v-if="notificationObj.selection.length" class="body-2 font-weight-medium"> ({{ notificationObj.selection.length }}) </span>
                </v-col>
                <v-col cols="5" class="text-end">
                  <template v-if="notificationObj.selection.length">
                    <v-btn
                      icon color="error lighten-1" small elevation="12" class="mx-1" :title="$t('delete')"
                      @click="deleteHandler"
                    >
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                    <v-btn
                      icon color="primary lighten-4" small elevation="12" class="mx-1" :title="$t('markAsUnRead')"
                      @click="bulkUpdate({ type: 'unread' })"
                    >
                      <v-icon small> mdi-email-mark-as-unread </v-icon>
                    </v-btn>
                    <v-btn
                      icon color="success lighten-2" small elevation="12" class="mx-1" :title="$t('markAsRead')"
                      @click="bulkUpdate({ type: 'read' })"
                    >
                      <v-icon small> mdi-email </v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <!-- <v-btn
                      icon color="primary lighten-3" small @click="notificationObj.showlist = false; $router.push('/notifications')"
                      class="mx-1" :title="$t('openInNewTab')"
                    >
                      <v-icon small> mdi-open-in-new </v-icon>
                    </v-btn> -->
                    <v-btn icon color="error lighten-1" small @click="notificationObj.showlist = false" :title="$t('close')">
                      <v-icon small> mdi-close-circle </v-icon>
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text class="pa-0">
            <template v-if="notificationObj.list.length">
              <v-list dense :max-height="screenHeight - 107" class="overflow-y-auto">
                <template v-for="(notificationItem, index) in notificationObj.list">
                  <v-hover :key="index" v-slot="{ hover }">
                    <v-list-item
                      two-line :class="['cursor-pointer', (notificationItem.isread) ? '' : 'primary--text text--darken-1']"
                      @click.stop="bulkUpdate({ notificationId: notificationItem._id, index })"
                    >
                      <v-list-item-icon class="ma-auto py-3 px-0">
                        <template v-if="hover || notificationObj.selection.length">
                          <v-checkbox
                            hide-details dense class="mt-n4 mr-n2"
                            v-model="notificationObj.selection" :value="notificationItem._id"
                            @click.stop
                          ></v-checkbox>
                        </template>
                        <template v-else>
                          <div class="mt-n3">
                            <v-badge color="primary" dot offset-y="5" offset-x="5" bordered :value="!notificationItem.isread">
                              <v-icon size="20"> {{ notificationIconList[notificationItem.typeofnotification] || 'mdi-bell-outline' }} </v-icon>
                            </v-badge>
                          </div>
                        </template>
                      </v-list-item-icon>
                      <v-list-item-content class="pl-3 pr-1">
                        <v-list-item-title>
                          <v-container fluid class="pa-0">
                            <v-row dense>
                              <v-col cols="7" class="text-wrap" align-self="center">
                                <span class="htmlMarginZero" v-html="notificationItem.title"></span>
                              </v-col>
                              <v-col cols="5" class="text-end text-wrap" style="font-size: 0.7rem;" align-self="center">
                                <span class="grey--text text--darken-1"> {{ notificationItem.datetime }} </span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-container fluid class="pa-0">
                            <v-row dense>
                              <v-col cols="10" class="text-wrap" align-self="center">
                                <span class="htmlMarginZero" v-html="notificationItem.message"></span>
                              </v-col>
                              <v-col cols="2" class="text-end pr-0" align-self="center">
                                <v-btn
                                  v-if="notificationItem.isread"
                                  icon x-small color="primary"
                                  class="mr-n1"
                                  @click.stop="bulkUpdate({ type: 'unread', notificationId: notificationItem._id, index })"
                                >
                                  <v-icon size="13"> mdi-email </v-icon>
                                </v-btn>
                                <v-btn icon x-small color="error lighten-1" @click.stop="deleteHandler({ notificationId: notificationItem._id })">
                                  <v-icon size="13"> mdi-delete </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-hover>
                </template>
              </v-list>
            </template>
            <template v-else>
              <div style="height: 50vh;" class="fill-height d-flex align-center justify-center body-2 font-weight-medium grey--text text--darken-1">
                {{ $t('noNewNotificationsFound') }}
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </template>
    <template>
      <v-container fluid v-if="!showMoreModules">
        <v-row class="ma-0 pt-7 px-3">
          <v-col cols="12" :style="{ background: $vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : '#1976d2' }">
            <v-container fluid>
              <v-row v-if="userDetails.systemconfig && userDetails.systemconfig.systemlogo">
                <v-col cols="12" class="d-flex justify-center pa-0">
                  <v-avatar size="70" class="mb-1">
                    <v-img :src="`${imageRootURL}${userDetails.systemconfig.systemlogo}`" height="100%" width="100%"
                    :lazy-src="`${imageRootURL}${userDetails.systemconfig.systemlogo}`"></v-img>
                  </v-avatar>
                  <span style="text-overflow: ellipsis;" class="text-wrap font-weight-black text-h5 my-auto mx-7" :style="{ color: systemDetails.textcolor || 'white' }">
                    {{ userDetails.systemconfig.systemname || 'SYFol' }}
                  </span>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-img width="215" class="mx-auto" :lazy-src="require(`@/assets/${appName}_logo.png`)" :src="require(`@/assets/${appName}_logo.png`)"></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="fieldsLoading" class="mt-6 mx-3">
          <v-col v-for="item in 12" :key="item" cols="4">
            <v-sheet class="ma-1">
              <v-skeleton-loader class="mx-auto" type="image" height="82"></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row justify="center" v-else>
          <v-col cols="11" class="px-2 py-4">
            <v-card flat class="py-1 custom-border"  :color="$vuetify.theme.dark ? '' : '#fafafa'">
              <template v-for="(item) in commonList">
                <v-row :key="`${item.id}_items`" no-gutters class="pa-0">
                  <template v-for="(navItem, navItemIndex) in item.list">
                    <v-col cols="4" :class="['pa-3', item.id === 'dashboardNavigation' ? hasNextRow(navItemIndex, item.list) ? 'grid-border-bottom' : '' : 'grid-border-bottom', (navItemIndex + 1) % 3 ? 'grid-border-right' : '', $vuetify.theme.dark ? 'grid-border-color' : '']"
                    :key="`${navItem._id}_module`" v-if="navItem.isShow" :style="$vuetify.theme.dark ? '' : 'background: #fafafa;'">
                      <v-card height="100%" flat @click="navItem.showMore ? showMoreModules = true : $router.push(navItem.to)" :color="$vuetify.theme.dark ? '' : '#fafafa'">
                        <div class="text-center">
                          <v-avatar>
                            <v-icon :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'indigo darken-2'" size="30">
                              {{ navItem.icon ? navItem.icon : 'mdi-cube' }}
                            </v-icon>
                          </v-avatar>
                        </div>
                        <v-card-text :class="['text-center pa-0 font-weight-medium grey--text', $vuetify.theme.dark ? 'text--lighten-1' : 'text--darken-2']">
                          {{ navItem.isStatic ? $t(navItem.name) : $t(navItem.plural_label) }}
                        </v-card-text>
                      </v-card>
                      <!-- <v-card flat elevation="0" height="100%" :to="navItem.to" color="" :class="[isNotendItem(index) ? 'grid-border-right' : '', 'pa-1']">
                      </v-card> -->
                    </v-col>
                  </template>
                </v-row>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <more-icons v-else @hideMoreModules="showMoreModules = false"></more-icons>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moreicons from './MoreModules.vue'
import eventBus from '../../main'
export default {
  data () {
    return {
      screenHeight: window.innerHeight,
      dashboardNavigation: [],
      imageRootURL: process.env.VUE_APP_IMAGE_URL,
      navigationItems: [
        { _id: 'static_item0', icon: 'mdi-view-dashboard', to: '/widgets_dashboard', isStatic: true, name: 'dashboard', isShow: true },
        { _id: 'static_item4', icon: 'mdi-calendar', to: '/calendar', isStatic: true, name: 'calendar', isShow: true },
        // { _id: 'static_item2', icon: 'mdi-clock-outline', to: '/timer', isStatic: true, name: 'timer', isShow: false },
        // { _id: 'static_item3', icon: 'mdi-timer', to: '/stemp', isStatic: true, name: 'stemp', isShow: false },
        { _id: 'static_item1', icon: 'mdi-palette-swatch', to: '/tickets', isStatic: true, name: 'tickets', isShow: false }
      ],
      fieldsLoading: false,
      appName: process.env.VUE_APP_LOGO_ICONS_FOLDER,
      showMoreModules: false,
      notificationObj: {
        showlist: false,
        newNotificationFound: false,
        list: [],
        selection: []
      },
      notificationIconList: {
        timer: 'mdi-timer'
      }
    }
  },
  components: {
    'more-icons': moreicons
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'systemDetails', 'getTimerSettings', 'getNewNotificationFoundValue']),
    // newNotificationFound: {
    //   get (value) {
    //     return value
    //   },
    //   set (value) {
    //     this.$store.commit('setNewNotificationsFoundValue', value)
    //   }
    // },
    commonList () {
      const navigationItems = this.$formatter.cloneVariable(this.navigationItems)
      if (this.userDetails.allowedfeatures && this.userDetails.allowedfeatures.length > 0) {
        if (this.userDetails.allowedfeatures.includes(this.TICKETS)) navigationItems.find(x => x.name === 'tickets').isShow = true
        // if (this.userDetails.allowedfeatures.includes(this.TIMER)) {
        //   const timerindex = navigationItems.findIndex(x => x.name === 'timer')
        //   if (timerindex >= 0) navigationItems[timerindex].isShow = true
        //   const stempIndex = navigationItems.findIndex(x => x.name === 'stemp')
        //   if (stempIndex !== -1) {
        //     navigationItems[stempIndex].isShow = this.getTimerSettings.enablestamptime
        //     if (stempIndex >= 0) navigationItems[stempIndex].isShow = true
        //   }
        // }
      }
      return [{
        id: 'navigationItems',
        list: navigationItems
      }, {
        id: 'dashboardNavigation',
        list: this.dashboardNavigation
      }]
    }
  },
  created () {
    this.fieldsLoading = true
    this.$store.dispatch('getModuleList').then(() => {
      const moduleArray = this.listOfModules.filter(x => x.isactive && !x.showonlyassubmodule)
      const tempArray = this.$formatter.groupBy(moduleArray, 'is_internal')
      let newTempModuleList = []
      Object.keys(tempArray).forEach((key) => {
        newTempModuleList = [...newTempModuleList, ...tempArray[key]]
      })
      newTempModuleList.forEach(element => { element.isShow = true })
      this.dashboardNavigation = this.$formatter.cloneVariable(newTempModuleList)
      const maxNumOfDefaultWidgets = 8
      if (this.dashboardNavigation.length > maxNumOfDefaultWidgets) {
        this.dashboardNavigation.splice(maxNumOfDefaultWidgets, this.dashboardNavigation.length - maxNumOfDefaultWidgets)
        // const moreModules = { icon: 'mdi-dots-horizontal', plural_name: 'More', to: '/custom_modules', isShow: true, isMoreOption: true }
        const moreModules = { icon: 'mdi-dots-horizontal', plural_label: 'More', to: '/custom_modules', isShow: true, showMore: true }
        this.dashboardNavigation.push(moreModules)
      }
    }).finally(() => {
      this.fieldsLoading = false
    })
  },
  mounted () {
    // listening for eventBus
    eventBus.$on('new-notification', this.animateNotificationIcon)
    // set screenheight on window resize
    window.onresize = () => {
      this.screenHeight = window.innerHeight
    }
  },
  methods: {
    isNotendItem (index) {
      var item = index + 1
      return item % 3 !== 0
      /* var endPositions = [3, 6, 9, 12, 15]
      return !endPositions.includes(item) */
    },
    hasNextRow (index, list) {
      var mainItemPosition = index + 1
      var item = mainItemPosition
      if (item > 3) item = parseInt(item / 3)
      let nextRow = item === 1 ? 3 : 1
      if (item === 2) nextRow = 2
      return (mainItemPosition + nextRow) <= list.length
      /* var item = index + 3
      return item < list.length */
    },
    animateNotificationIcon () {
      this.$root.$emit('snackbar', { snackbar: true, color: 'primary', text: 'newNotificationsFound' })
    },
    async getAllUnReadNotifications () {
      const { data: result } = await this.$api.execute('get', 'appnotification?unread=true') || {}
      this.notificationObj.list = ((result && result.data) ? result.data : (result.data || [])).map((item) => ({
        ...item,
        datetime: this.$formatter.formatDate(item.modified_at, '', `${this.userDetails.dateformat} HH:mm`)
      }))
      this.notificationObj.showlist = true
    },
    updateSelections () {
      if (this.notificationObj.selection.length) {
        if (this.notificationObj.selection.length === this.notificationObj.list.length) {
          this.notificationObj.selection = []
          return
        }
      }
      this.notificationObj.selection = this.notificationObj.list.map((item) => item._id)
    },
    deleteHandler ({ notificationId } = {}) {
      this.$root.$emit('showPermanentDeleteDialog', {
        ids: (notificationId) ? [notificationId] : this.notificationObj.selection,
        url: 'appnotification/delete_multiple',
        onResolve: async () => {
          await this.getAllUnReadNotifications()
        },
        onComplete: async () => {
          this.notificationObj.selection = []
        }
      })
    },
    bulkUpdate ({ type = 'read', notificationId, index } = {}) {
      if (notificationId) this.notificationObj.selection = [notificationId]
      this.$api.execute('put', `appnotification/bulk_update?isread=${(type === 'read')}`, { ids: this.notificationObj.selection })
        .then(() => {
          if (notificationId && (index >= 0)) this.$set(this.notificationObj.list[index], 'isread', (type === 'read'))
          else this.getAllUnReadNotifications()
          this.notificationObj.selection = []
        })
    }
  }
}
</script>
<style>
.grid-border-top {
  border-top: 0.85px solid #19191912 !important;
}
.grid-border-right {
  border-right: 0.85px solid #19191912 !important;
}
.grid-border-bottom {
  border-bottom: 0.85px solid #19191912 !important;
}
.grid-border-left {
  border-left: 0.85px solid #19191912 !important;
}
.grid-border-color {
  border-color: white !important;
}
.custom-margin {
  margin-top: 28%;
}
.custom_bg_color {
  background: #1976d2 !important;
}
.custom-border {
   border-bottom: none !important;
}
</style>
